import Vue from 'vue';
import Plyr from 'plyr';

Vue.component('slider', {
    props: ['slides', 'aspectRatio'],

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            currentSlide: 0,
            transition: 'slide-next',
            trailerPlayer: null,
            autoplayTimer: null,
        };
    },

    /**
     * Called when the Vue instance is mounted.
     *
     * @return {[type]} [description]
     */
    mounted() {
        this.autoplay();

        this.initTrailer();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initTrailer() {
            const trailer = document.getElementById('trailer');
            if (!trailer) {
                return;
            }

            const md = new MobileDetect(window.navigator.userAgent);

            let options = {};

            if (md.phone()) {
                options = {
                    fullscreen: {
                        iosNative: true,
                    },
                };
            }

            this.player = new Plyr(this.$refs.video, options);

            this.pageElement = document.getElementById('app');
            this.player.on('ready', this.onPlayerReady);
            this.player.on('play', this.onTrailerPlaying);
            this.player.on('pause', this.onTrailerPaused);
            this.player.on('seeking', this.onTrailerSeeking);

            // Fullscreen handles
            this.player.on('enterfullscreen', this.onEnterFullScreen);
            this.player.on('exitfullscreen', this.onExitFullScreen);

            this.playBtnTl = new TimelineMax({ paused: true });
            this.playBtnTl.to('#slider .play', 0.3, { scale: 1.25, ease: Power3.easeOut });
            this.playBtnTl.to('#slider .play', 0.15, { scale: 0, autoAlpha: 0, ease: Power3.easeOut });
            this.playBtnTl.timeScale(0.8);
        },

        autoplay() {
            // Kill the previous tween
            TweenMax.killTweensOf(this.next);

            // Reset it
            TweenMax.delayedCall(5, this.next);
        },

        next() {
            if (this.inTransition) {
                return;
            }

            this.transition = 'slide-next';
            if (this.currentSlide === this.slides.length - 1) {
                this.currentSlide = 0;
                return;
            }

            this.currentSlide += 1;
        },

        previous() {
            if (this.inTransition) {
                return;
            }

            this.transition = 'slide-previous';
            if (this.currentSlide === 0) {
                this.currentSlide = this.slides.length - 1;
                return;
            }

            this.currentSlide -= 1;
        },

        playTrailer() {
            this.player.play();

            // TODO: Remove eslint disable in refactor time.
            // eslint-disable-next-line eqeqeq
            if (this.aspectRatio == 1) {
                const slider = document.getElementById('slider');
                zenscroll.center(slider);
            }

            const md = new MobileDetect(window.navigator.userAgent);

            if (md.phone()) {
                this.player.fullscreen.enter();
            }
        },

        onEnterFullScreen() {
            this.pageElement.classList.add('trailer-fullscreen');
        },

        onExitFullScreen() {
            this.pageElement.classList.remove('trailer-fullscreen');

            const md = new MobileDetect(window.navigator.userAgent);

            if (md.phone()) {
                this.player.pause();
            }
        },

        onTrailerPlaying(event) {
            this.pageElement.classList.add('trailer-playing');
            this.playBtnTl.play();
        },

        onTrailerPaused() {
            if (this.player.fullscreen.active) {
                return;
            }

            this.pageElement.classList.remove('trailer-playing');
            this.playBtnTl.reverse();
        },

        onTrailerSeeking() {
            this.pageElement.classList.add('trailer-playing');
            this.playBtnTl.play();
        },

        onPlayerReady(event) {
            //
        },

        // Vue transition hooks
        beforeEnter(el) {
            this.inTransition = true;
        },

        // the done callback is optional when
        // used in combination with CSS
        enter(el, done) {
            this.inTransition = true;

            done();
        },

        afterEnter(el) {
            this.inTransition = false;
            this.autoplay();
        },

        enterCancelled(el) {
            this.inTransition = false;
        },
    },
});
