import Vue from 'vue';

Vue.component('restaurant-menu', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            showMenuList: false,
            showSubMenuList: false,
            menuSection: 'lunch',
            menuSubSection: 'wijn',
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {

    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        /**
         * Toggle a subcard of the restaurant menu by using the
         * event's current target.
         *
         * @param {Event} event
         */
        toggleMenuSubCard(event) {
            const currentSubOption = document.getElementById('current-sub-option');
            const newSubOption = event.currentTarget.getAttribute('data-cardsection');
            const newSubOptionName = event.currentTarget.innerHTML;

            this.menuSubSection = newSubOption;
            currentSubOption.innerHTML = newSubOptionName;

            this.showSubMenuList = !this.showSubMenuList;
        },

        /**
         * Toggle the menu list.
         */
        toggleMenuList() {
            this.showMenuList = !this.showMenuList;
        },

        /**
         * Toggle the sub-menu list.
         */
        toggleSubMenuList() {
            this.showSubMenuList = !this.showSubMenuList;
        },

        /**
         * Toggles the main menu card.
         *
         * @param {Event} event
         */
        toggleMenuCard(event) {
            const currentOption = document.getElementById('current-option');
            const newOption = event.currentTarget.getAttribute('data-cardsection');

            this.menuSection = newOption;
            currentOption.innerHTML = newOption;

            this.showMenuList = !this.showMenuList;
        },
    },
});
