import Vue from 'vue';
import Calendar from '../../classes/Calendar';

Vue.component('main-scheduler', {
    props: ['todayShows', 'tomorrowShows'],

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            shows: {},
            today: true,
            tomorrow: false,
            date: false,
            showFullSchedule: false,
            selectedDay: null,
            datepickerOpen: false,

            calendar: new Calendar(),
        };
    },

    computed: {
        // Getter for the calendar to make template work in a readable way
        day: {
            get() {
                return this.calendar.day;
            },
            set(newValue) {
                this.calendar.day = newValue;
            },
        },

        numberOfShows() {
            return Object.keys(this.shows).length;
        },
    },

    /**
     * Vue component mounted hook
     *
     * @return {Void}
     */
    mounted() {
        this.showToday();

        this.calendar.getAvailability();

        EventBus.$on('events-for-date', (data) => {
            this.today = false;
            this.tomorrow = false;
            this.date = true;
            this.shows = {};
            Vue.nextTick(() => {
                this.shows = data;
                this.cacheQueries();
            });
            this.toggleDatepicker();
        });

        this.navigation = document.getElementById('full-list');
        this.cacheQueries();

        this.fullToggleElement = document.querySelector('.buffer span');
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        /**
         * Caches queries for performance optimization
         *
         * @returns {Void}
         */
        cacheQueries() {
            Vue.nextTick(() => {
                this.fullItemList = document.querySelectorAll('#full-list .item');
                this.reversedFullItemList = [].slice.call(this.fullItemList, 0).reverse();
            });
        },

        /**
         * Shows today's shows
         */
        showToday() {
            if (this.showFullSchedule) {
                this.toggleSchedule();
            }

            this.today = true;
            this.tomorrow = false;
            this.date = false;
            this.datepickerOpen = false;
            this.shows = {};
            Vue.nextTick(() => {
                this.shows = this.todayShows;
                this.cacheQueries();
            });
        },

        /**
         * Show tommorrow's shows
         */
        showTomorrow() {
            if (this.showFullSchedule) {
                this.toggleSchedule();
            }

            this.today = false;
            this.tomorrow = true;
            this.date = false;
            this.datepickerOpen = false;
            this.shows = {};
            Vue.nextTick(() => {
                this.shows = this.tomorrowShows;
                this.cacheQueries();
            });
        },

        /**
         * Show a selected date show's
         */
        showDate() {
            if (this.showFullSchedule) {
                this.toggleSchedule();
            }

            this.today = false;
            this.tomorrow = false;
            this.date = true;

            this.toggleDatepicker();
        },

        /**
         * Fetches available times for the given day
         *
         * @param {Object} day
         */
        selectDay(day) {
            this.calendar.selectDay(day);
            this.selectedDay = day;
        },

        /**
         * Toggles the schedule block
         *
         * @return {Void}
         */
        toggleSchedule(event) {
            this.showFullSchedule = !this.showFullSchedule;

            if (this.showFullSchedule) {
                // Show
                TweenMax.to(this.navigation, 1.2, {
                    className: '+=open',
                    ease: Quad.easeOut,
                });

                TweenMax.staggerTo(this.fullItemList, 0.4, { autoAlpha: 1 }, 0.1);
                this.fullToggleElement.innerHTML = this.fullToggleElement.getAttribute('data-text-active');

                return;
            }

            // Hide
            TweenMax.to(this.navigation, 0.8, {
                className: '-=open',
                ease: Quint.easeOut,
            });
            TweenMax.staggerTo(this.reversedFullItemList, 0.4, { autoAlpha: 0 }, 0.1);
            this.fullToggleElement.innerHTML = this.fullToggleElement.getAttribute('data-text-default');
        },

        /**
         * Toggles the datepicker.
         *
         * @returns {Void}
         */
        toggleDatepicker() {
            this.datepickerOpen = !this.datepickerOpen;
        },

        /**
         * Raised when the user clicks on a time in the schedule.
         *
         * @param {Event} event
         */
        onTimeClicked(event) {
            EventBus.$emit('toggle-ticketholder', event);
        },
    },
});
