import Vue from 'vue';
import Slider from '../plugins/Slider';

Vue.component('media-slider', {

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            currentSlide: 0,
            slider: null,
            transition: 'slide-out-up',
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        if (document.getElementById('inline-slider')) {
            this.slider = new Slider('inline-slider', { autoplay: false, interval: 5000 });
        }
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        /**
         * Load next slide
         */
        nextSlide() {
            this.transition = 'slide-out-up';

            if (this.currentSlide === this.slider.slides.length - 1) {
                this.currentSlide = 0;
                this.slider.loadByClick(this.currentSlide);
                return;
            }

            this.currentSlide += 1;
            this.slider.loadByClick(this.currentSlide);
        },

        /**
         * Load previous slide
         */
        previousSlide() {
            this.transition = 'slide-out-down';

            if (this.currentSlide === 0) {
                this.currentSlide = this.slider.slides.length - 1;
                this.slider.loadByClick(this.currentSlide);
                return;
            }

            this.currentSlide -= 1;
            this.slider.loadByClick(this.currentSlide);
        },
    },
});
