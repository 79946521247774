import Vue from 'vue';
import Calendar from '../classes/Calendar';

Vue.component('overview-datepicker', {
    props: ['dateUrl'],

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            visible: false,
            calendar: new Calendar(),
        };
    },

    computed: {
        day: {
            get() {
                return this.calendar.day;
            },
            set(newValue) {
                this.calendar.day = newValue;
            },
        },
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.calendar.getAvailability();

        EventBus.$on('toggle-overview-datepicker', this.toggle);
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        /**
         * Toggles the component visibility.
         */
        toggle() {
            this.visible = !this.visible;
        },

        /**
         * Fetches available times for the given day
         *
         * @param {object} dayObj
         */
        selectDay(dayObj) {
            this.calendar.selectDay(dayObj);
        },
    },
});
