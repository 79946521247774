import Vue from 'vue';

Vue.component('view-toggle', {
    methods: {
        gridView() {
            const url = new URL(window.location.href);
            url.searchParams.delete('token');
            url.searchParams.set('view', 'grid');
            window.location.replace(url.toString());
        },

        listView() {
            const url = new URL(window.location.href);
            url.searchParams.delete('token');
            url.searchParams.set('view', 'list');
            window.location.replace(url.toString());
        },
    },
});
