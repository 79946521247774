import Vue from 'vue';
import Plyr from 'plyr';

Vue.component('festival-item', {
    props: ['eventId'],

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            trailerPlayer: null,
        };
    },

    /**
     * Called when the Vue instance is mounted.
     *
     * @return {[type]} [description]
     */
    mounted() {
        this.initTrailer();

        EventBus.$on('open-festival-event', (eventId) => {
            // TODO: Remove eslint disable in refactor time.
            // eslint-disable-next-line eqeqeq
            if (eventId == this.eventId) {
                this.playBtnTl.reverse();
            }
        });

        EventBus.$on('close-festival-event', (eventId) => {
            // TODO: Remove eslint disable in refactor time.
            // eslint-disable-next-line eqeqeq
            if (eventId == this.eventId) {
                this.pageElement.classList.remove('trailer-playing');
                this.player.pause();
                this.playBtnTl.play();
            }
        });
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initTrailer() {
            const trailer = document.getElementById(`trailer-${this.eventId}`);
            if (!trailer) {
                return;
            }

            this.player = new Plyr(this.$refs.video, {
                controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
                playsinline: true,
            });
            this.pageElement = this.$el;
            this.player.on('ready', this.onPlayerReady);
            this.player.on('play', this.onTrailerPlaying);
            this.player.on('pause', this.onTrailerPaused);
            this.player.on('seeking', this.onTrailerSeeking);

            // Fullscreen handles
            this.player.on('enterfullscreen', this.onEnterFullScreen);
            this.player.on('exitfullscreen', this.onExitFullScreen);

            this.playBtnTl = new TimelineMax({ paused: true });
            this.playBtnTl.to(this.$refs.play, 0.3, { scale: 1.25, ease: Power3.easeOut });
            this.playBtnTl.to(this.$refs.play, 0.15, { scale: 0, autoAlpha: 0, ease: Power3.easeOut });
            this.playBtnTl.timeScale(0.8);
        },

        playTrailer() {
            this.player.play();
        },

        onEnterFullScreen() {
            this.pageElement.classList.add('trailer-fullscreen');
        },

        onExitFullScreen() {
            this.pageElement.classList.remove('trailer-fullscreen');
        },

        onTrailerPlaying(event) {
            this.pageElement.classList.add('trailer-playing');
            this.playBtnTl.play();
        },

        onTrailerPaused() {
            if (this.player.fullscreen.active) {
                return;
            }

            this.pageElement.classList.remove('trailer-playing');
            this.playBtnTl.reverse();
        },

        onTrailerSeeking() {
            this.pageElement.classList.add('trailer-playing');
            this.playBtnTl.play();
        },

        onTrailerEnded(event) {
            this.pageElement.classList.remove('trailer-playing');
            this.playBtnTl.reverse();
        },

        onPlayerReady(event) {
            //
        },
    },
});
