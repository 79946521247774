import Vue from 'vue';

Vue.component('corona-popup', {

    data() {
        return {
            showPopup: false,
        };
    },

    mounted() {
        const overlay = this.getCookie('corona-popup');

        // TODO: Remove eslint disable in refactor time.
        // eslint-disable-next-line eqeqeq
        if (overlay == -1) {
            this.setCookie('corona-popup', 1, 45);
        }

        // TODO: Remove eslint disable in refactor time.
        // eslint-disable-next-line eqeqeq
        if (overlay != 1) {
            this.showPopup = true;
        }
    },

    methods: {
        /**
         * Sets overlay cookie and hides the overlay
         *
         * @return {Void}
         */
        clickOverlay() {
            this.setCookie('corona-popup', 1, 45);
            this.showPopup = false;
        },

        clickLink() {
            this.setCookie('corona-popup', 1, 45);
        },

        /**
         * Sets a given cookie
         *
         * @param {String} cname
         * @param {Number} cvalue
         * @param {Number} exdays
         */
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            const expires = `expires=${d.toUTCString()}`;
            document.cookie = `${cname}=${cvalue}; ${expires}`;
        },

        /**
         * Gets a certain cookie
         *
         * @param  {String} cname
         * @return {String}
         */
        getCookie(cname) {
            const name = `${cname}=`;
            if (!document.cookie) {
                return '-1';
            }
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }

                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return '';
        },
    },
});
