import Vue from 'vue';
import Errors from '../classes/Errors';

Vue.component('education-form-component', {
    data() {
        return {
            formErrors: new Errors(),
            formCompleted: false,
            submitting: false,
            widget: null,
        };
    },

    mounted() {
        const captchaEl = this.$el.querySelector('.g-recaptcha');

        if (captchaEl) {
            this.initRecaptcha(captchaEl);
        }
    },

    methods: {
        initRecaptcha(element) {
            const init = (el) => {
                this.widget = grecaptcha.render(el, {
                    sitekey: el.dataset.sitekey,
                    theme: 'dark',
                });
            };

            if (!window.captchaLoaded) {
                EventBus.$once('captcha-ready', () => {
                    init(element);
                });
                return;
            }

            init(element);
        },

        onSubmit() {
            const { form } = this.$refs;

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            axios.post('/', new FormData(form))
                .then((response) => {
                    this.submitting = false;
                    const result = response.data;

                    if (result.data.errors) {
                        this.formErrors.clear();
                        this.formErrors.record(result.data.errors);

                        this.scrollToFirstError();
                        return;
                    }

                    // Success
                    this.formCompleted = true;
                    this.formErrors.clear();
                    window.zenscroll.toY(0, 0);
                })
                .catch((error) => {
                    this.submitting = false;
                    console.log(error.response);
                });
        },
    },
});
