import Vue from 'vue';

Vue.component('serie-scheduler', {
    props: ['events'],

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            // days: {},
            showFullSchedule: false,
            showFullTimeTickets: false,
        };
    },

    /**
     * [mounted description]
     *
     * @return {[type]} [description]
     */
    mounted() {

    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        /**
         * Toggles the schedule block
         *
         * @return {Void}
         */
        toggleSchedule() {
            this.showFullSchedule = !this.showFullSchedule;
        },

        /**
         * Toggles the time-tickets block
         *
         * @return {Void}
         */
        toggleFullTimeTickets() {
            this.showFullTimeTickets = !this.showFullTimeTickets;
        },

        /**
         * Raised when the user clicks on a time in the schedule.
         *
         * @param {Event} event
         */
        onTimeClicked(event) {
            EventBus.$emit('toggle-ticketholder', event);
        },
    },
});
