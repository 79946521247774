import Vue from 'vue';
import Plyr from 'plyr';

Vue.component('video-player', {
    data() {
        return {
            player: null,
        };
    },

    mounted() {
        const md = new MobileDetect(window.navigator.userAgent);

        let options = {};

        if (md.phone()) {
            options = {
                fullscreen: {
                    iosNative: true,
                },
            };
        }

        this.player = new Plyr(this.$refs.videoplayer, options);

        this.player.on('play', this.onPlay);
        this.player.on('pause', this.onPause);
    },

    methods: {
        playVideo() {
            this.player.play();
        },

        onPlay() {
            const holder = document.querySelector('.videoplayer-holder');
            holder.classList.add('playing');
        },

        onPause() {
            const holder = document.querySelector('.videoplayer-holder');
            holder.classList.remove('playing');
        },
    },
});
