import Swiper, { Pagination, Navigation } from 'swiper';
import Vue from 'vue';

Vue.component('poster-carousel', {
    data() {
        return {
            swiper: null,
        };
    },

    props: {
        amount: {
            type: Number,
            default: 3,
        },
    },

    mounted() {
        this.swiper = new Swiper('.swiper', {
            modules: [Pagination, Navigation],
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            },
            navigation: {
                nextEl: '.swiper-button-next--custom',
                prevEl: '.swiper-button-prev--custom',
            },
            slidesPerView: 3,
            spaceBetween: 30,
            breakpoints: {
                768: {
                    slidesPerView: this.amount,
                },
            },
        });
    },
});
