import Vue from 'vue';

/**
 * Format a datetime string
 *
 * @param {string} datetime - Datetime string that is not very user friendly
 * @param {string} format - The format the datetime string should have
 * @return {string} The blended color.
 */
Vue.filter('eventDay', (datetime) => {
    if (!datetime) {
        return '';
    }

    return moment(datetime).format('ddd D MMM');
});

/**
 * Format a datetime string
 *
 * @param {string} datetime - Datetime string that is not very user friendly
 * @param {string} format - The format the datetime string should have
 * @return {string} The blended color.
 */
Vue.filter('time', (datetime) => {
    if (!datetime) {
        return '';
    }

    return moment(datetime).format('HH:mm');
});

/**
 * Abbreviate a day string
 *
 * @param {string} day - A day string like 'Sunday'
 * @param {string} length - The length of the abbreviated string
 * @return {string} Abbreviated day string, like S for 'Sunday'
 */
Vue.filter('day', (day, length) => {
    if (!length) {
        return day.charAt(0);
    }

    return day.charAt(length);
});

/**
 * Format a datetime string
 *
 * @param {string} datetime - Datetime string that is not very user friendly
 * @param {string} format - The format the datetime string should have
 * @return {string} The blended color.
 */
Vue.filter('month', (datetime) => {
    if (!datetime) {
        return '';
    }

    return moment(datetime).format('MMM YYYY');
});

/**
 * Format a datetime string
 *
 * @param {string} datetime - Datetime string that is not very user friendly
 * @param {string} format - The format the datetime string should have
 * @return {string} The blended color.
 */
Vue.filter('schedulerDay', (datetime) => {
    if (!datetime) {
        return '';
    }

    return moment(datetime).format('dd DD MMM');
});

/**
 * Return the correct url for a given show.
 *
 * @param  {Object} show - Datetime string that is not very user friendly
 * @return {string} The blended color.
 */
Vue.filter('showUrl', (show) => {
    if (show.related_entry && show.type === 'Special') {
        return show.related_entry.url;
    }

    return show.event_url;
});

/**
 * Returns string without dots in it.
 *
 * @param  {String} string - String that is about to be manipulated.
 * @return {string} The manipulated string.
 */
Vue.filter('noDots', (string) => string.split('.').join(''));
