import Vue from 'vue';
import Errors from '../classes/Errors'; // eslint-disable-line

Vue.component('event-form-component', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            formErrors: new Errors(),
            formCompleted: false,
            submitting: false,
            dateTimeUnavailable: false,
        };
    },

    watch: {
        dateTimeUnavailable(val) {
            if (val) {
                // this.$refs.date.value = '';
                this.$refs.startTime.value = '';
                this.$refs.endTime.value = '';
            }

            // this.onSubmit();
        },
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        const captchaEl = this.$el.querySelector('.g-recaptcha');
        if (captchaEl) {
            this.initRecaptcha(captchaEl);
        }
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initRecaptcha(element) {
            const init = (el) => {
                const widget = grecaptcha.render(el, {
                    sitekey: el.dataset.sitekey,
                    theme: 'dark',
                });
            };

            if (!window.captchaLoaded) {
                EventBus.$once('captcha-ready', () => {
                    init(element);
                });
                return;
            }

            init(element);
        },

        onSubmit() {
            const { form } = this.$refs;

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            axios.post('/', new FormData(form))
                .then((response) => {
                    this.submitting = false;
                    const result = response.data;

                    if (result.data.errors) {
                        this.formErrors.clear();
                        this.formErrors.record(result.data.errors);

                        this.scrollToFirstError();
                        return;
                    }

                    // Success
                    this.formCompleted = true;
                    this.formErrors.clear();
                    window.zenscroll.toY(0, 0);

                    // window.location = this.$refs.redirectUrl.value;
                })
                .catch((error) => {
                    this.submitting = false;
                    console.log(error.response);
                });
        },
    },
});
