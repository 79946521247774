export default class Slider {
    /**
     * Slider plugin constructor
     * @param  {string} elementName Id of the slider element
     * @param  {object} options     Object containing user defined options
     * @return {void}
     */
    constructor(elementName, options) {
        // Set plugin variables
        this.element = document.getElementById(elementName);
        this.slides = document.querySelectorAll('.slider-panel');
        this.numberOfSlides = this.slides.length;
        this.activeSlide = 0;
        this.timer = null;

        // Load default options and merge them
        this.options = {
            autoplay: false,
            autoPlayInterval: 7500,
            infinite: true,
        };

        // Extend options
        this.options = this.setOptions(options, this.options);

        // Initialize the slider
        this.init();
    }

    /**
     * Initialize the plugin with the settings and variables
     * set in the constructor.
     *
     */
    init() {
        const sensitivity = 25;

        if (this.options.autoplay) {
            this.timer = setTimeout(() => { this.loadNext(); }, this.options.autoPlayInterval);
        }

        const sliderManager = new Hammer.Manager(this.element);
        const panOptions = {
            threshold: 0,
            pointers: 0,
            direction: 6, // only horizontal panning to prevent blocking of the normal vertical scroll
        };
        sliderManager.add(new Hammer.Pan(panOptions));
        /* eslint-disable complexity */
        sliderManager.on('pan', (e) => {
            const percentage = 100 / this.numberOfSlides * e.deltaX / window.innerWidth;
            const percentageCalculated = percentage - 100 / this.numberOfSlides * this.activeSlide;

            // this.element.style.transform = 'translateX( ' + percentageCalculated + '% )'
            TweenMax.set(this.element, { x: `${percentageCalculated}%` });

            // Return if final
            if (e.isFinal) {
                return;
            }

            // Snap to new slide
            if (e.velocityX > 1) {
                this.goTo(this.activeSlide - 1);
            } else if (e.velocityX < -1) {
                this.goTo(this.activeSlide + 1);
            } else if (percentage <= -(sensitivity / this.numberOfSlides)) {
                this.goTo(this.activeSlide + 1);
            } else if (percentage >= (sensitivity / this.numberOfSlides)) {
                this.goTo(this.activeSlide - 1);
            } else {
                this.goTo(this.activeSlide);
            }
        });
        /* eslint-enable complexity */
    }

    /**
     * Extends the options object.
     *
     * @param   {Object} target
     * @param   {Object} source
     * @returns {Object}
     */
    setOptions(target, source) {
        for (const key in source) {
            target[key] = source[key];
        }
        return target;
    }

    /**
     * Go the the given slide
     *
     * @param  {int} number
     */
    goTo(number) {
        // Stop it from doing weird things like moving to slides that don’t exist
        if (number < 0) {
            this.activeSlide = 0;
        } else if (number > this.numberOfSlides - 1) {
            this.activeSlide = this.numberOfSlides - 1;
        } else {
            this.activeSlide = number;
        }

        // Apply transformation & smoothly animate via .is-animating CSS
        const percentage = -(100 / this.numberOfSlides) * this.activeSlide;

        TweenMax.to(this.element, 0.6, { x: `${percentage}%` });

        if (this.options.autoplay) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => { this.loadNext(); }, this.options.autoPlayInterval);
        }
    }

    /**
     * Load the next slide by click
     * @param  {int} number
     */
    loadByClick(number) {
        this.goTo(number);
    }

    /**
     * Load the next slide
     *
     * @return {Void}
     */
    loadNext() {
        const activeSlideElement = document.querySelectorAll('#slider .slider-panel')[this.activeSlide];
        if (!activeSlideElement) {
            return;
        }

        const complete = () => {
            if (this.activeSlide + 1 > this.numberOfSlides - 1) {
                this.goTo(0, split);
            } else {
                this.goTo(this.activeSlide + 1, split);
            }
        };

        complete();
    }

    /**
     * Destroy the plugin events/animation. This method
     * leave the dom structure intact.
     */
    destroy() {
        clearTimeout(this.timer);
    }
}
