import Vue from 'vue';

Vue.component('event-scheduler', {
    props: ['shows'],

    /**
     * Vue.js instance reactive data variables.
     *
     * @returns {Object}
     */
    data() {
        return {
            // days: {},
            showFullSchedule: false,
            showFullTimeTickets: false,
        };
    },

    computed: {
        numberOfShows() {
            return Object.keys(this.shows).length;
        },
    },

    /**
     * Vue.js component mounted hook. Usefull for initializing stuff.
     *
     * @returns {Void}
     */
    mounted() {

    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        /**
         * Raised when the user clicks on a time in the schedule.
         *
         * @param {Event} event
         */
        onTimeClicked(event) {
            EventBus.$emit('toggle-ticketholder', event);
        },

        /**
         * Toggles the schedule block
         *
         * @returns {Void}
         */
        toggleSchedule() {
            this.showFullSchedule = !this.showFullSchedule;
        },

        /**
         * Toggles the time-tickets block
         *
         * @returns {Void}
         */
        toggleFullTimeTickets() {
            this.showFullTimeTickets = !this.showFullTimeTickets;
        },
    },
});
