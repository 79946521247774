export default class Calendar {
    /**
    * Create a new Calendar instance.
    */
    constructor() {
        this.today = moment();
        this.weekdays = moment.weekdaysShort(true);
        this.data = [];
        this.month = moment();
        this.day = null;
        this.time = null;
        this.activeHour = null;
        this.hours = [];
        this.activeDay = null;
    }

    /**
    * Fetches available times for the given day
    *
    * @param {object} dayObj
    */
    selectDay(dayObj) {
        if (!(dayObj.d && dayObj.available)) {
            return;
        }

        // Create an instance
        const day = moment(this.month).date(dayObj.d);

        // If it's in the past, exit
        if (day.isBefore(moment().add(1, 'days'), 'day')) {
            return;
        }

        if (this.activeDay) {
            this.activeDay.active = false;
        }

        this.activeDay = dayObj;
        this.activeDay.active = true;

        this.day = day;
        this.hours = [];

        // TODO: Setup url
        const url = `${window.applicationBaseUrl}actions/ticketlab-module/ticketlab/get-shows?date=${day.format('YYYY-MM-DD')}`;
        axios.get(url).then((response) => {
            EventBus.$emit('events-for-date', response.data);
        }, (response) => {
            console.log(response);
        });

        this.time = null;
    }

    /**
     * Fetch the availability data for a given month
     */
    getAvailability() {
        this.data = [];

        const url = `/actions/ticketlab-module/ticketlab/get-availability-for-month?year=${this.month.format('YYYY')}&month=${this.month.format('MM')}`;
        axios.get(url).then((response) => {
            this.days = [];

            const day = moment(this.month).date(1);
            const currentMonth = day.month();
            const now = moment();

            this.reponse = response.data;

            // Push a date while the day/month moment is current month
            do {
                // Create day moment
                this.days.push({
                    d: day.date(),
                    past: day.isBefore(now, 'day'),
                    today: day.isSame(now, 'day'),
                    date: day.format('YYYY-MM-DD'),
                    available: (response.data.indexOf(day.date()) > -1),
                });

                // Move on to the next day using Moment.js logic
                day.add(1, 'day');
            } while (day.month() === currentMonth);

            this.render();
        })
            .catch((response) => {
                console.log(response);
            });
    }

    /**
     * Navigate to the previous month
     */
    previousMonth() {
        const prevMonth = this.month.subtract(1, 'months');
        this.month = null;
        this.month = prevMonth;
        this.getAvailability();
    }

    /**
     * Navigate to the next month
     */
    nextMonth() {
        const nextMonth = this.month.add(1, 'months');
        this.month = null;
        this.month = nextMonth;
        this.getAvailability();
    }

    /**
     * Handle active day state
     *
     * @param  {Moment} dayObj
     * @return {Void}
     */
    handleActiveDay(dayObj) {
        if (this.activeDay) {
            this.activeDay.active = false;
        }

        this.activeDay = dayObj;
        this.activeDay.active = true;
    }

    /**
     * Render the show calendar
     */
    render() {
        const blocks = [];

        // Some padding at the beginning
        for (let p = 0; p < moment(this.month).date(1).weekday(); p++) {
            blocks.push({
                d: null,
            });
        }

        // Merge in the array of days
        blocks.push(...this.days);

        // Some padding at the end if needed
        if (blocks.length % 7) {
            for (let p = (7 - (blocks.length % 7)); p > 0; p--) {
                blocks.push({
                    d: null,
                });
            }
        }

        // Split the array into "chunks" of seven
        const rows = blocks.map((e, i) => (i % 7 === 0 ? blocks.slice(i, i + 7) : null)).filter((e) => e);

        this.data = rows;
    }
}
